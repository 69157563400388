/* @import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&family=Inter:wght@200;300;400;500;600;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* input:focus .hide-on-mobile  {
  display: none;
  /* background-color: yellow;r */

/* @media (max-height: 500px) {
  .hide-on-mobile {
    display: none;
  }
} */

/* prevent white space "bounce" after scrolling to the top of the page and the bottom */
body {
  overscroll-behavior: none;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  overflow: auto;
  height: 100%;
}

/* @layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply w-full bg-white border-gray-500 rounded-lg  focus:border-violet-500 
     hover:border-violet-500 shadow-none  my-2 focus-visible:border-violet-500;
  }
  [type="checkbox"],
  [type="radio"] {
    @apply border-gray-300 rounded text-violet-600 focus:ring-violet-500;
  }
} */

/*  */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
  text-decoration: none;
  margin-left: 5px;
  margin-right: 5px;
}

/*======= CSS variables =======*/

.signup-gradient {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(97deg, #70a6f7, #2b80ff);
  color: #70a6f7;
  font-weight: 600;
  line-height: 3;
  text-decoration: none;
}

.no-gradient {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(
    97deg,
    rgb(246, 135, 179),
    rgb(246, 34, 119)
  );
  color: rgb(246, 135, 179);
  font-weight: 600;
  line-height: 3;
  text-decoration: none;
}
.text-gradient {
  background: linear-gradient(
    97.53deg,
    rgb(246, 135, 179) 5.6%,
    rgb(123, 97, 255) 59.16%,
    rgb(22, 209, 161) 119.34%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.logo-gradient {
  background: linear-gradient(97.53deg, rgb(123, 97, 255), rgb(22, 209, 161));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.radial-gradient {
  background-image: radial-gradient(
      circle at left bottom,
      rgba(0, 193, 193, 0.396) 0%,
      transparent 30%
    ),
    radial-gradient(
      circle at right top,
      rgba(123, 97, 255, 0.44) 0%,
      transparent 50%
    );
}

:root {
  --chakra-ring-offset-color: #fff;
  --chakra-ring-color: rgba(66, 153, 225, 0.6);
  --chakra-ring-offset-shadow: 0 0 #0000;
  --chakra-ring-shadow: 0 0 #0000;
  --chakra-space-x-reverse: 0;
  --chakra-space-y-reverse: 0;
  --chakra-colors-transparent: transparent;
  --chakra-colors-current: currentColor;
  --chakra-colors-black: #000000;
  --chakra-colors-white: #ffffff;
  --chakra-colors-whiteAlpha-50: rgba(255, 255, 255, 0.04);
  --chakra-colors-whiteAlpha-100: rgba(255, 255, 255, 0.06);
  --chakra-colors-whiteAlpha-200: rgba(255, 255, 255, 0.08);
  --chakra-colors-whiteAlpha-300: rgba(255, 255, 255, 0.16);
  --chakra-colors-whiteAlpha-400: rgba(255, 255, 255, 0.24);
  --chakra-colors-whiteAlpha-500: rgba(255, 255, 255, 0.36);
  --chakra-colors-whiteAlpha-600: rgba(255, 255, 255, 0.48);
  --chakra-colors-whiteAlpha-700: rgba(255, 255, 255, 0.64);
  --chakra-colors-whiteAlpha-800: rgba(255, 255, 255, 0.8);
  --chakra-colors-whiteAlpha-900: rgba(255, 255, 255, 0.92);
  --chakra-colors-blackAlpha-50: rgba(0, 0, 0, 0.04);
  --chakra-colors-blackAlpha-100: rgba(0, 0, 0, 0.06);
  --chakra-colors-blackAlpha-200: rgba(0, 0, 0, 0.08);
  --chakra-colors-blackAlpha-300: rgba(0, 0, 0, 0.16);
  --chakra-colors-blackAlpha-400: rgba(0, 0, 0, 0.24);
  --chakra-colors-blackAlpha-500: rgba(0, 0, 0, 0.36);
  --chakra-colors-blackAlpha-600: rgba(0, 0, 0, 0.48);
  --chakra-colors-blackAlpha-700: rgba(0, 0, 0, 0.64);
  --chakra-colors-blackAlpha-800: rgba(0, 0, 0, 0.8);
  --chakra-colors-blackAlpha-900: rgba(0, 0, 0, 0.92);
  --chakra-colors-gray-50: #f7fafc;
  --chakra-colors-gray-100: #edf2f7;
  --chakra-colors-gray-200: #e2e8f0;
  --chakra-colors-gray-300: #cbd5e0;
  --chakra-colors-gray-400: #a0aec0;
  --chakra-colors-gray-500: #718096;
  --chakra-colors-gray-600: #4a5568;
  --chakra-colors-gray-700: #2d3748;
  --chakra-colors-gray-750: #212631;
  --chakra-colors-gray-785: #1a202c;
  --chakra-colors-gray-800: #181c27;
  --chakra-colors-gray-815: #1a1f2b;
  --chakra-colors-gray-825: #191e2a;
  --chakra-colors-gray-850: #191d28;
  --chakra-colors-gray-900: #171923;
  --chakra-colors-red-50: #fff5f5;
  --chakra-colors-red-100: #fff8f8;
  --chakra-colors-red-200: #fde3e3;
  --chakra-colors-red-300: #fac1c0;
  --chakra-colors-red-400: #f5918f;
  --chakra-colors-red-500: #ef5350;
  --chakra-colors-red-600: #dd4d4a;
  --chakra-colors-red-700: #c74543;
  --chakra-colors-red-800: #ae3c3b;
  --chakra-colors-red-900: #923231;
  --chakra-colors-orange-50: #fffaf0;
  --chakra-colors-orange-100: #feebc8;
  --chakra-colors-orange-200: #fbd38d;
  --chakra-colors-orange-300: #f6ad55;
  --chakra-colors-orange-400: #ed8936;
  --chakra-colors-orange-500: #dd6b20;
  --chakra-colors-orange-600: #c05621;
  --chakra-colors-orange-700: #9c4221;
  --chakra-colors-orange-800: #7b341e;
  --chakra-colors-orange-900: #652b19;
  --chakra-colors-yellow-50: #fffff0;
  --chakra-colors-yellow-100: #fefcbf;
  --chakra-colors-yellow-200: #faf089;
  --chakra-colors-yellow-300: #f6e05e;
  --chakra-colors-yellow-400: #ecc94b;
  --chakra-colors-yellow-500: #d69e2e;
  --chakra-colors-yellow-600: #b7791f;
  --chakra-colors-yellow-700: #975a16;
  --chakra-colors-yellow-800: #744210;
  --chakra-colors-yellow-900: #5f370e;
  --chakra-colors-green-50: #e6faf5;
  --chakra-colors-green-100: #a1ecd9;
  --chakra-colors-green-200: #5cdfbd;
  --chakra-colors-green-300: #33d7ad;
  --chakra-colors-green-400: #16d1a1;
  --chakra-colors-green-500: #00cd98;
  --chakra-colors-green-600: #00a67b;
  --chakra-colors-green-700: #008562;
  --chakra-colors-green-800: #00684d;
  --chakra-colors-green-900: #004f3a;
  --chakra-colors-teal-50: #e6fffa;
  --chakra-colors-teal-100: #b2f5ea;
  --chakra-colors-teal-200: #81e6d9;
  --chakra-colors-teal-300: #4fd1c5;
  --chakra-colors-teal-400: #38b2ac;
  --chakra-colors-teal-500: #319795;
  --chakra-colors-teal-600: #2c7a7b;
  --chakra-colors-teal-700: #285e61;
  --chakra-colors-teal-800: #234e52;
  --chakra-colors-teal-900: #1d4044;
  --chakra-colors-blue-50: #ebeffe;
  --chakra-colors-blue-100: #b5c4fc;
  --chakra-colors-blue-200: #7f99fb;
  --chakra-colors-blue-300: #5f80fa;
  --chakra-colors-blue-400: #486ff9;
  --chakra-colors-blue-500: #3761f9;
  --chakra-colors-blue-600: #2d4ec9;
  --chakra-colors-blue-700: #243ea1;
  --chakra-colors-blue-800: #1c317e;
  --chakra-colors-blue-900: #15255f;
  --chakra-colors-cyan-50: #edfdfd;
  --chakra-colors-cyan-100: #c4f1f9;
  --chakra-colors-cyan-200: #9decf9;
  --chakra-colors-cyan-300: #76e4f7;
  --chakra-colors-cyan-400: #0bc5ea;
  --chakra-colors-cyan-500: #00b5d8;
  --chakra-colors-cyan-600: #00a3c4;
  --chakra-colors-cyan-700: #0987a0;
  --chakra-colors-cyan-800: #086f83;
  --chakra-colors-cyan-900: #065666;
  --chakra-colors-purple-50: #faf5ff;
  --chakra-colors-purple-100: #e9d8fd;
  --chakra-colors-purple-200: #d6bcfa;
  --chakra-colors-purple-300: #b794f4;
  --chakra-colors-purple-400: #9f7aea;
  --chakra-colors-purple-500: #805ad5;
  --chakra-colors-purple-600: #6b46c1;
  --chakra-colors-purple-700: #553c9a;
  --chakra-colors-purple-800: #44337a;
  --chakra-colors-purple-900: #322659;
  --chakra-colors-pink-50: #fff5f7;
  --chakra-colors-pink-100: #fed7e2;
  --chakra-colors-pink-200: #fbb6ce;
  --chakra-colors-pink-300: #f687b3;
  --chakra-colors-pink-400: #ed64a6;
  --chakra-colors-pink-500: #d53f8c;
  --chakra-colors-pink-600: #b83280;
  --chakra-colors-pink-700: #97266d;
  --chakra-colors-pink-800: #702459;
  --chakra-colors-pink-900: #521b41;
  --chakra-colors-linkedin-50: #e8f4f9;
  --chakra-colors-linkedin-100: #cfedfb;
  --chakra-colors-linkedin-200: #9bdaf3;
  --chakra-colors-linkedin-300: #68c7ec;
  --chakra-colors-linkedin-400: #34b3e4;
  --chakra-colors-linkedin-500: #00a0dc;
  --chakra-colors-linkedin-600: #008cc9;
  --chakra-colors-linkedin-700: #0077b5;
  --chakra-colors-linkedin-800: #005e93;
  --chakra-colors-linkedin-900: #004471;
  --chakra-colors-facebook-50: #e8f4f9;
  --chakra-colors-facebook-100: #d9dee9;
  --chakra-colors-facebook-200: #b7c2da;
  --chakra-colors-facebook-300: #6482c0;
  --chakra-colors-facebook-400: #4267b2;
  --chakra-colors-facebook-500: #385898;
  --chakra-colors-facebook-600: #314e89;
  --chakra-colors-facebook-700: #29487d;
  --chakra-colors-facebook-800: #223b67;
  --chakra-colors-facebook-900: #1e355b;
  --chakra-colors-messenger-50: #d0e6ff;
  --chakra-colors-messenger-100: #b9daff;
  --chakra-colors-messenger-200: #a2cdff;
  --chakra-colors-messenger-300: #7ab8ff;
  --chakra-colors-messenger-400: #2e90ff;
  --chakra-colors-messenger-500: #0078ff;
  --chakra-colors-messenger-600: #0063d1;
  --chakra-colors-messenger-700: #0052ac;
  --chakra-colors-messenger-800: #003c7e;
  --chakra-colors-messenger-900: #002c5c;
  --chakra-colors-whatsapp-50: #dffeec;
  --chakra-colors-whatsapp-100: #b9f5d0;
  --chakra-colors-whatsapp-200: #90edb3;
  --chakra-colors-whatsapp-300: #65e495;
  --chakra-colors-whatsapp-400: #3cdd78;
  --chakra-colors-whatsapp-500: #22c35e;
  --chakra-colors-whatsapp-600: #179848;
  --chakra-colors-whatsapp-700: #0c6c33;
  --chakra-colors-whatsapp-800: #01421c;
  --chakra-colors-whatsapp-900: #001803;
  --chakra-colors-twitter-50: #e5f4fd;
  --chakra-colors-twitter-100: #c8e9fb;
  --chakra-colors-twitter-200: #a8dcfa;
  --chakra-colors-twitter-300: #83cdf7;
  --chakra-colors-twitter-400: #57bbf5;
  --chakra-colors-twitter-500: #1da1f2;
  --chakra-colors-twitter-600: #1a94da;
  --chakra-colors-twitter-700: #1681bf;
  --chakra-colors-twitter-800: #136b9e;
  --chakra-colors-twitter-900: #0d4d71;
  --chakra-colors-telegram-50: #e3f2f9;
  --chakra-colors-telegram-100: #c5e4f3;
  --chakra-colors-telegram-200: #a2d4ec;
  --chakra-colors-telegram-300: #7ac1e4;
  --chakra-colors-telegram-400: #47a9da;
  --chakra-colors-telegram-500: #0088cc;
  --chakra-colors-telegram-600: #007ab8;
  --chakra-colors-telegram-700: #006ba1;
  --chakra-colors-telegram-800: #005885;
  --chakra-colors-telegram-900: #003f5e;
  --chakra-colors-darkTeal-300: #3f6d6c;
  --chakra-colors-darkTeal-500: #144241;
  --chakra-colors-primary: #3761f9;
  --border-color: var(--chakra-colors-whiteAlpha-300);

  --bg-address-color: rgba(127, 153, 251, 0.16);
  --border-input: rgb(95, 128, 250);

  --white-color: #fff;
  --dark-color: #222;
  --body-bg-color: #fff;
  --section-bg-color: #202834;
  --navigation-item-hover-color: #3b5378;
  --text-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  --box-shadow: 0 5px 25px rgb(0 0 0 / 20%);
  --scroll-bar-color: #000;
  --scroll-thumb-color: #282f4e;
  --scroll-thumb-hover-color: #454f6b;
}

.dark {
  color-scheme: dark;
}

[data-testid="flowbite-tooltip-target"] {
  width: 100%;
}

.h-mobile {
  height: calc(100% - 5rem);
}

/*======= Scroll bar =======*/

/* ::-webkit-scrollbar {
  width: 11px;
  background: var(--chakra-colors-gray-700);
}

::-webkit-scrollbar-thumb {
  width: 100%;
  background: var(---chakra-colors-gray-800);
  border-radius: 2em;
}

::-webkit-scrollbar-thumb:hover {
  background: var(---chakra-colors-gray-900);
} */

/* 
::-webkit-scrollbar {
  width: 11px;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
} */
